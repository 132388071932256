<template>
  <va-form
    resource="syndrome_questions"
    :id="id"
    :item="item"
    v-model="model"
    disable-redirect
    @saved="submit"
  >
    <v-card-text>
      <AutoComplete
        label="Condição Médica"
        :model="model.syndrome_id"
        slug="syndrome_id"
        endpoint="default/Syndrome"
        slugId="id"
        slugDesc="title"
        @onChangeTextInput="onChangeTextInput"
      >
      </AutoComplete>
      <TextFieldArea
        slug="description"
        :model="model.description"
        label="Descrição"
        @onChangeTextInput="onChangeTextInput"
      ></TextFieldArea>

      <AutoComplete
        label="Faixa de Meses"
        :model="model.range_id"
        slug="range_id"
        endpoint="default/Range"
        slugId="id"
        slugDesc="description"
        @onChangeTextInput="onChangeTextInput"
      >
      </AutoComplete>
      <TextField
        slug="sequence"
        :model="model.sequence"
        label="Sequência"
        mask="###"
        @onChangeTextInput="onChangeTextInput"
      ></TextField>

      <TextField
        slug="question_weight"
        :model="model.question_weight"
        label="Peso da Questão"
        @onChangeTextInput="onChangeTextInput"
      ></TextField>
      <AutoComplete
        label="Tipo Questão"
        :model="model.type_question_id"
        slug="type_question_id"
        endpoint="default/TypeQuestion"
        slugId="id"
        slugDesc="description"
        @onChangeTextInput="onChangeTextInput"
      >
      </AutoComplete>
      <div>
        <!-- Pergunta de Multipla escolhas -->
        <div v-if="tipo_question == 1">
          <v-combobox
            v-model="multiSelectPergunta"
            :items="multiSelectPergunta"
            label="Descreva as opções que seram mostradas"
            multiple
            chips
          ></v-combobox>
          <v-select
            v-model="multiSelectResposta"
            :items="multiSelectPergunta"
            label="Escolha as opções que estão corretas"
            multiple
            chips
            clearable
            :change="setjson()"
          ></v-select>
        </div>
        <!-- Pergunta do tipo Sim ou Não -->
        <div v-if="tipo_question == 2">
          <label>Qual a resposta correta?</label>
          <v-radio-group v-model="checkResposta">
            <v-radio
              v-for="n in check"
              :key="n"
              :label="`${n}`"
              :value="n"
              :change="setjson()"
            ></v-radio>
          </v-radio-group>
        </div>
        <!-- Pergunta de Multipla escolhas -->
        <div v-if="tipo_question == 3">
          <v-combobox
            v-model="multiSelectPergunta"
            :items="multiSelectPergunta"
            label="Descreva as opções que seram mostradas"
            multiple
            chips
          ></v-combobox>
          <v-select
            v-model="multiSelectResposta"
            :items="multiSelectPergunta"
            label="Escolha a opções correta"
            chips
            clearable
            :change="setjson()"
          ></v-select>
        </div>
      </div>
      <v-col
        v-if="type_media != null && imagem == null && this.item.id != null"
      >
        <!-- midia -->
        <AutoComplete
          label="Tipo de Mídia"
          :model="type_media"
          slug="type_media"
          endpoint="combo/syndrome_questions/type_media"
          slugId="id"
          slugDesc="description"
          @onChangeTextInput="onChangeTypeMedia"
        >
        </AutoComplete>
        <TextField
          v-if="type_media != 'I'"
          :model="model.midia"
          label="Url"
          @onChangeTextInput="onImageLinkMidia"
        ></TextField>
      </v-col>
      <!-- ===================== DADOS IMAGEM ========================= -->
      <v-file-input
        v-if="
          this.imagem == null && this.item.id != null && this.type_media == 'I'
        "
        outlined
        dense
        show-size
        v-on:change="onImageChange"
        label="Imagem"
        filled
        prepend-icon="mdi-camera"
      ></v-file-input>
      <v-snackbar v-model="snackbar" :color="color">
        {{ text }}
      </v-snackbar>
    </v-card-text>
    <v-col v-if="this.type_media == 'I' && this.imagem != null">
      <va-form>
        <v-col>
          <v-img :src="`${imagem['link']}`"></v-img>
        </v-col>
      </va-form>
    </v-col>
    <v-col v-if="this.imagem != null && this.type_media != 'I'">
      <a :href="this.imagem.link">{{ this.imagem.link }}</a>
    </v-col>
    <v-row>
      <v-col>
        <v-btn
          v-if="this.midia != null"
          class="ma-2"
          color="#1976d2"
          @click="submit"
        >
          <v-icon left>mdi-floppy</v-icon>
          Salvar
        </v-btn>
        <va-save-button v-else @click="submit" label="Salvar"></va-save-button>
        <v-btn
          v-if="this.imagem != null"
          class="ma-2"
          color="red"
          @click="deleteImagem"
        >
          <v-icon>mdi-delete</v-icon>
          Excluir Midia
        </v-btn>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
import TextField from "@/components/detail/v_text_field.vue";
import TextFieldArea from "@/components/detail/v_text_area.vue";
import AutoComplete from "@/components/detail/v_auto_complete.vue";
import service from "@/components/detail/service_combo";

export default {
  props: ["id", "title", "item"],
  components: { TextField, TextFieldArea, AutoComplete },

  data() {
    return {
      model: this.item,
      check: ["Sim", "Não"],
      multiSelectPergunta: [],
      multiSelectResposta: [],
      checkResposta: null,
      tipo_question: null,
      imagem: null,
      type_media: null,
      //Variavel que guarda o binario da imagem
      midia: null,
      snackbar: false,
      text: "",
      color: "gray"
    };
  },
  created() {
    if (this.model.type_question_id != null) {
      this.setReturnJson();
    }
    this.getImagem();
  },
  methods: {
    onChangeTextInput(val) {
      if (val.slug == "type_question_id") {
        switch (val.value) {
          //Multiselect
          case 1:
            this.tipo_question = 1;
            break;
          //Sim ou Não
          case 2:
            this.tipo_question = 2;
            break;
          //Select
          case 3:
            (this.multiSelectResposta = []), (this.tipo_question = 3);
            break;
          default:
            this.tipo_question = null;
            break;
        }
      }
      this.model[val.slug] = val.value;
    },
    onChangeTypeMedia(val) {
      this.type_media = val.value;
    },

    //Envio das Perguntas e Resposta
    setjson() {
      var json = {};
      switch (this.tipo_question) {
        //Multiselect
        case 1:
          json = {
            type_question: 1,
            questions: this.multiSelectPergunta,
            answer: this.multiSelectResposta
          };
          this.model.options_json = json;
          break;
        //Sim ou Não
        case 2:
          json = {
            type_question: 2,
            questions: this.check,
            answer: this.checkResposta
          };
          this.model.options_json = json;
          break;
        //Select
        case 3:
          json = {
            type_question: 3,
            questions: this.multiSelectPergunta,
            answer: this.multiSelectResposta
          };
          this.model.options_json = json;
          break;
        default:
          break;
      }
    },
    //Retorno da api - Perguntas Resposta
    setReturnJson() {
      switch (this.model.type_question_id) {
        //Multiselect
        case 1:
          this.tipo_question = 1;
          this.multiSelectPergunta = this.model.options_json.questions;
          this.multiSelectResposta = this.model.options_json.answer;
          break;
        //Sim ou Não
        case 2:
          this.tipo_question = 2;
          this.check = this.model.options_json.questions;
          this.checkResposta = this.model.options_json.answer;
          break;
        //Select
        case 3:
          this.tipo_question = 3;
          this.multiSelectPergunta = this.model.options_json.questions;
          this.multiSelectResposta = this.model.options_json.answer;
          break;
        default:
          break;
      }
    },
    getImagem() {
      try {
        service
          .getImagem({
            syndrome_question_id: this.item.id
          })
          .then(resp => {
            if (resp.length > 0) {
              this.type_media = resp[0].type;
              this.model.midia = resp[0].link;
              this.imagem = resp[0];
            } else {
              this.type_media = "";
            }
          });
      } catch (e) {
        this.null = null;
      }
    },
    onImageChange(file) {
      let formData = new FormData();
      formData.append("arquive", file);
      formData.append("table", "SyndromeQuestion");
      formData.append("table_id", this.item.id);
      formData.append("create_user", 1);
      formData.append("featured", 0);
      formData.append("type", "I");
      this.midia = formData;
    },
    onImageLinkMidia(file) {
      let formData = new FormData();
      formData.append("arquive", file.value);
      formData.append("table", "SyndromeQuestion");
      formData.append("table_id", this.item.id);
      formData.append("create_user", 1);
      formData.append("featured", 0);
      if (this.type_media == "V") {
        formData.append("type", "V");
      } else {
        formData.append("type", "L");
      }
      this.midia = formData;
    },
    submit() {
      if (this.midia != null) {
        return service.postImagem({ item: this.midia }).then(resp => {
          try {
            if (resp.data.success == true) {
              this.getImagem();
              this.$emit("saved");
            } else {
              this.text = resp.data.message.toString();
              this.color = "red";
              this.snackbar = true;
            }
          } catch (error) {
            this.text = error.toString();
            this.color = "red";
            this.snackbar = true;
          }
        });
      } else {
        this.$emit("saved");
      }
    },
    deleteImagem() {
      var msg = confirm("Deseja mesmo remover está midea da Condição Médica?");
      if (msg) {
        return service.deleteImagem({ id: this.imagem.id }).then(resp => {
          try {
            if (resp.status == 200) {
              this.text = resp.data.message.toString();
              this.color = "green";
              this.snackbar = true;
              this.imagem = null;
              this.type_media = "";
              this.model.midia = null;
            } else {
              this.text = resp.data.message.toString();
              this.color = "red";
              this.snackbar = true;
            }
          } catch (error) {
            this.text = error.toString();
            this.color = "red";
            this.snackbar = true;
          }
        });
      }
    }
  }
};
</script>
