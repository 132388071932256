<template>
  <div>
    <dashboard-filter
      @onChangeFilterDashboard="onChangeFilterDashboard"
    ></dashboard-filter>
    <v-row>
      <!-- Quantidade de Questionarios respondidos -->
      <v-col cols="12" sm="6" lg="2">
        <base-material-stats-card
          color="red"
          icon="mdi-message-question"
          title="Qtde Total"
          :value="this.qtd_questionnaire_performed"
          sub-icon="mdi-clock"
          sub-text="Atualizado"
        />
      </v-col>
      <!-- Quantidade de resultados por Condição Médica -->
      <v-col
        v-for="syndrome in qtd_syndrome"
        :key="syndrome.description"
        cols="12"
        sm="6"
        lg="2"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          :title="
            syndrome.description != null
              ? syndrome.description
              : 'Sem Condição Médica'
          "
          :value="syndrome.qtde"
          sub-icon="mdi-clock"
          sub-text="Atualizado"
        />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="12" sm="12" lg="6">
        <v-data-table
          :headers="headers"
          :items="items_list"
          disable-sort
          disable-pagination
          item-key="id"
          class="elevation-1"
          hide-default-footer
          show-expand
        >
          <template v-slot:expanded-item="{ item }">
            <td :colspan="12">
              <dashboard-view-question :item="item"></dashboard-view-question>
            </td>
          </template>
        </v-data-table>
      </v-col>
      <!-- Grafico de resultados por Condição Médica-->
      <v-col
        v-if="chartOptions.xaxis.categories != null"
        cols="12"
        sm="12"
        lg="6"
      >
        <v-card>
          <v-card-actions>
            <div class="display-2">
              Resultados por Condição Médica
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
          <apexchart
            type="bar"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import service from "@/services/BaseService";
import apexchart from "vue-apexcharts";
import qs from "qs";
const date = new Date();

const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
const lastDay = new Date(
  date.getFullYear(),
  date.getMonth() + 1,
  0
).toISOString();

export default {
  components: { apexchart },
  data() {
    return {
      expanded: [],
      singleExpand: false,
      headers: [
        {
          text: "Numero diagnóstico",
          align: "start",
          value: "id"
        },
        { text: "Peso da Resposta", value: "weight_answers" },
        { text: "Condição Médica", align: "start", value: "result" },
        { text: "UF", align: "start", value: "uf" }
      ],
      items_list: [],
      qtd_questionnaire_performed: "0",
      qtd_syndrome: [],
      filtro: {
        start_date: firstDay,
        end_date: lastDay
      },
      chartOptions: {
        chart: {
          id: "basic-bar",
          animations: {
            speed: 200
          }
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            distributed: true
          }
        },
        xaxis: {
          categories: null
        }
      },
      series: [
        {
          name: "series-1",
          data: []
        }
      ]
    };
  },
  created() {
    this.buscaDadosDashboard();
  },
  methods: {
    async buscaDadosDashboard() {
      var getvalues = new service(
        `dashboard?${qs.stringify(this.filtro, { arrayFormat: "repeat" })}`
      );
      await getvalues
        .find()
        .then(value => {
          if (value.data.code == 200) {
            this.qtd_questionnaire_performed = value.data.data.qtd_questionnaire_performed.toString();
            this.qtd_syndrome = value.data.data.qtd_type_syndrome;
            this.items_list = value.data.data.result_question_patient;
            this.setGraphicSyndrome(value.data.data);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    async setGraphicSyndrome(data) {
      var categories = [];
      var series = [];
      data.qtd_type_syndrome.forEach(element => {
        if (element.description == null) {
          element.description = "Sem Condição Médica";
        }
        categories.push(element.description.toString());
        series.push(parseInt(element.qtde));
      });
      this.chartOptions.xaxis.categories = categories;
      this.series[0].data = series;
    },
    onChangeFilterDashboard(val) {
      this.chartOptions.xaxis.categories = null;
      this.filtro = val;
      this.buscaDadosDashboard();
    }
  }
};
</script>
