<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <base-material-card>
        <template v-slot:heading>
          <div class="display-2">
            {{ titleForm }}
          </div>
        </template>
        <patient-form :id="id" :item="item" @saved="onSaved"></patient-form>
      </base-material-card>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <patient-filter
        @onChangeFilterPatient="onChangeFilterPatient"
        @onChangeNewPatient="onChangeNewPatient"
      ></patient-filter>
      <va-list
        :include="includes"
        disable-create-redirect
        ref="list"
        disable-global-search
        disable-export
        disable-create
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          @item-action="onAction"
          disable-edit-redirect
          disable-create-redirect
          disable-select
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      includes: [{ api: `default/Patient/search/params` }, {}],
      fields: [
        { source: "id", sortable: true },
        { source: "name", label: "Nome", sortable: true },
        { source: "cpf_responsible", label: "CPF Responsável", sortable: true },
        {
          source: "birth_date",
          label: "Dt. Nascimento",
          type: "date",
          sortable: true
        },
        { source: "cep", sortable: true },
        { source: "address", label: "Endereço", sortable: true },
        { source: "number", label: "Numero", sortable: true },
        { source: "complement", label: "Complemento", sortable: true },
        { source: "city", label: "Cidade", sortable: true },
        { source: "uf", label: "UF", sortable: true }
      ],
      asideTitle: null,
      titleForm: null,
      id: null,
      item: null,
      show: false
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onAction({ title, id, item }) {
      this.asideTitle = null;
      setTimeout(() => {
        this.titleForm = title;
        this.asideTitle = " ";
      }, 10);
      this.id = id;
      if (item == null) {
        this.item = {};
      } else {
        this.item = item;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    onChangeFilterPatient(val) {
      this.includes[1] = val;
      this.$refs.list.fetchData();
    },
    onChangeNewPatient() {
      this.asideTitle = null;
      this.item = {};
      this.id = null;

      setTimeout(() => {
        this.titleForm = "Novo Paciente";
        this.asideTitle = " ";
      }, 10);
    }
  },
  mounted() {
    document.getElementsByClassName(
      "v-sheet theme--light v-toolbar v-toolbar--flat"
    )[0].style.height = "0px";
  }
};
</script>
