<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{}">
      <v-text-field
        outlined
        dense
        v-mask="'##/##/####'"
        v-model="dateFormatted"
        :label="label"
        append-icon="mdi-calendar"
        @click:append="menu = true"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import moment from "moment";

export default {
  props: ["label", "model", "initialValue"],

  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: moment(new Date()).format("DD/MM/YYYY"),
      menu: false
    };
  },
  created() {
    if (this.initialValue != null) {
      this.dateFormatted = moment(this.initialValue, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    dateFormatted(val) {
      if (val.toString().length == 10) {
        this.dateFormatted = val;
        var data = moment(val, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ss");
        this.$emit("onChangeData", {
          model: this.model,
          value: data
        });
      }
    },
    date(val) {
      var data = moment(val).format("DD/MM/YYYY");
      this.dateFormatted = data;
    }
  }
};
</script>
