<template>
  <v-combobox
    outlined
    dense
    :label="label"
    v-model="valueInput"
    :items="combo"
    item-text="description"
    :rules="this.required == true ? [v => !!v || 'Item is required'] : []"
  ></v-combobox>
</template>
<script>
export default {
  props: ["label", "model", "slug", "items", "required"],
  data() {
    return {
      valueInput: this.model,
      combo: this.items
    };
  },
  created() {
    this.combo.forEach(element => {
      if (element.id == this.model) {
        this.valueInput = element;
      }
    });
  },
  watch: {
    valueInput(val) {
      this.$emit("onChangeTextInput", {
        slug: this.slug,
        value: val.id
      });
    }
  }
};
</script>
