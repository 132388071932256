export default [
  {
    name: "user",
    api: "default/User",
    icon: "mdi-account",
    label: "Usuários",
    routes: ["list", "edit", "create"]
  },
  // {
  //   name: "patient",
  //   api: "default/Patient",
  //   icon: "mdi-clipboard-account-outline",
  //   label: "Paciente",
  //   routes: ["list", "edit", "create"]
  // },
  {
    name: "syndrome",
    api: "default/Syndrome",
    icon: "mdi-message-question",
    label: "Síndrome",
    routes: ["list", "edit", "create"]
  },
  {
    name: "syndrome_questions",
    api: "default/SyndromeQuestion",
    icon: "mdi-message-question",
    label: "Síndrome Questões",
    routes: ["list", "edit", "create"]
  },
  {
    name: "syndrome_result",
    api: "default/SyndromeResult",
    icon: "mdi-account",
    label: "Síndrome Resultado",
    routes: ["list", "edit", "create"]
  },
  {
    name: "patient_syndrome",
    api: "default/PatientSyndrome",
    icon: "mdi-account",
    label: "Síndrome do Paciente",
    routes: ["list", "edit", "create"]
  },
  {
    name: "patient_syndrome_answer",
    api: "default/PatientSyndromeAnswer",
    icon: "mdi-account",
    label: "Resposta da Síndrome do Paciente",
    routes: ["list", "edit", "create"]
  },
  {
    name: "type_question",
    api: "default/TypeQuestion",
    icon: "mdi-account",
    label: "Tipo de Pergunta",
    routes: ["list", "edit", "create"]
  },
  {
    name: "company",
    api: "default/Company",
    icon: "mdi-domain",
    label: "Empresa",
    routes: ["list", "edit", "create"]
  },
  {
    name: "person",
    api: "default/Person",
    icon: "mdi-account-group",
    label: "ONG's e Especialistas",
    routes: ["list", "edit", "create"]
  },
  {
    name: "range",
    api: "default/Range",
    icon: "mdi-arrange-send-backward",
    label: "Range",
    routes: ["list", "edit", "create"]
  },
  {
    name: "combo_status_user",
    api: "combo/user/status"
  }
];
