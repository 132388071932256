<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <base-material-card>
        <template v-slot:heading>
          <div class="display-2">
            {{ titleForm }}
          </div>
        </template>
        <user-form :id="id" :item="item" @saved="onSaved"></user-form>
      </base-material-card>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <user-filter @onChangeFilterUser="onChangeFilterUser"></user-filter>
      <va-list
        :include="includes"
        :filters="filters"
        disable-create-redirect
        ref="list"
        disable-global-search
        disable-export
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          @item-action="onAction"
          disable-edit-redirect
          disable-create-redirect
          disable-select
          disable-clone
          show-expand
        >
          <template v-slot:expanded-item="{ item }">
            <v-col>
              <v-row>
                <v-col>
                  <label>Especialidades</label>
                </v-col>
              </v-row>
              <v-row>
                <div v-for="esp in item.specialties" :key="esp.id">
                  <v-col>
                    <v-chip color="green" text-color="white">
                      <strong
                        >Especialidade: {{ esp.description }} - RQE:
                        {{ esp.RQE }}
                      </strong>
                    </v-chip>
                  </v-col>
                </div>
              </v-row>
            </v-col>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      includes: [{ api: `default/User/search/params` }, { status: "B" }],
      filters: [
        { source: "id", label: "N° Registro" },
        { source: "name", label: "Nome" },
        { source: "email", label: "E-mail" },
        { source: "crm", label: "CRM" }
      ],
      fields: [
        { source: "id", sortable: true },
        { source: "name", label: "Nome", sortable: true },
        { source: "email_login", label: "Email", sortable: true },
        { source: "crm", label: "CRM", sortable: true },
        { source: "phone", label: "Telefone", sortable: true },
        { source: "phone_cell", label: "Celular", sortable: true },
        { source: "uf", label: "UF", sortable: true },
        { source: "desc_status", label: "Status", sortable: true }
      ],
      asideTitle: null,
      titleForm: null,
      id: null,
      item: null,
      show: false
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onAction({ title, id, item }) {
      this.asideTitle = null;
      setTimeout(() => {
        this.titleForm = title;
        this.asideTitle = " ";
      }, 10);
      this.id = id;
      if (item == null) {
        this.item = {};
        this.item.status = "A";
      } else {
        this.item = item;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    onChangeFilterUser(val) {
      this.includes[1] = val;
      this.$refs.list.fetchData();
    }
  }
  // mounted() {
  //   document.getElementsByClassName(
  //     "v-sheet theme--light v-toolbar v-toolbar--flat"
  //   )[0].style.height = "0px";
  // }
};
</script>
