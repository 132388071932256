<template>
  <!-- Input sem mascara -->
  <div v-if="mask == null">
    <v-textarea outlined dense :label="label" v-model="valueInput"></v-textarea>
  </div>
  <!-- Input com mascara -->
  <div v-else>
    <v-textarea
      outlined
      dense
      :label="label"
      v-mask="mask"
      v-model="valueInput"
    ></v-textarea>
  </div>
</template>
<script>
export default {
  props: ["label", "model", "mask", "slug"],
  data() {
    return {
      valueInput: this.model
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    valueInput(val) {
      this.$emit("onChangeTextInput", {
        slug: this.slug,
        value: val
      });
    }
  }
};
</script>
