<template>
  <va-form>
    <v-col sm="12">
      <v-row justify="end" v-if="filtro != null">
        <v-col cols="12" sm="12" lg="12">
          <v-row justify="end">
            <v-col cols="12" sm="6" lg="2">
              <DatePicker
                label="Data Inicio"
                model="start_date"
                v-model="filtro.start_date"
                :initialValue="filtro.start_date"
                @onChangeData="onChangeData"
              ></DatePicker>
            </v-col>
            <v-col cols="12" sm="6" lg="2">
              <DatePicker
                label="Data Fim"
                model="end_date"
                v-model="filtro.end_date"
                :initialValue="filtro.end_date"
                @onChangeData="onChangeData"
              ></DatePicker>
            </v-col>
            <v-col cols="12" sm="6" lg="3">
              <AutoComplete
                label="Condição Médica"
                :model="filtro.syndrome_result_id"
                slug="syndrome_result_id"
                endpoint="default/SyndromeResult"
                slugId="id"
                slugDesc="description"
                @onChangeTextInput="onChangeTextInput"
              >
              </AutoComplete>
            </v-col>
            <v-col cols="12" sm="6" lg="3">
              <AutoComplete
                label="Médico"
                :model="filtro.create_user"
                slug="create_user"
                endpoint="default/User"
                slugId="id"
                slugDesc="name"
                @onChangeTextInput="onChangeTextInput"
              >
              </AutoComplete>
            </v-col>
            <v-col cols="12" sm="6" lg="2">
              <AutoComplete
                label="UF"
                :model="filtro.uf"
                slug="uf"
                endpoint="combo/localidade/estado"
                slugId="id"
                slugDesc="description"
                @onChangeTextInput="onChangeTextInput"
              >
              </AutoComplete>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="clearFilter()">
              <v-icon>mdi-filter</v-icon>
              Limpar Filtros
            </v-btn>
            <v-btn text color="primary" @click="filter()">
              <v-icon>mdi-filter</v-icon>
              Filtrar
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </va-form>
</template>

<script>
import DatePicker from "@/components/detail/date_picker.vue";
import AutoComplete from "@/components/detail/v_auto_complete.vue";

const date = new Date();

const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();

const lastDay = new Date(
  date.getFullYear(),
  date.getMonth() + 1,
  0
).toISOString();

export default {
  components: {
    DatePicker,
    AutoComplete
  },
  data: function() {
    return {
      menu: false,
      filtro: {}
    };
  },
  created() {
    this.filtro.start_date = firstDay;
    this.filtro.end_date = lastDay;
  },
  methods: {
    onChangeTextInput(val) {
      this.filtro[val.slug] = val.value;
    },
    onChangeData(val) {
      this.filtro[val.model] = val.value;
    },
    filter() {
      this.$emit("onChangeFilterDashboard", this.filtro);
    },
    clearFilter() {
      this.filtro = null;
      setTimeout(() => {
        this.filtro = {};
        this.filtro.start_date = firstDay;
        this.filtro.end_date = lastDay;
        this.$emit("onChangeFilterDashboard", this.filtro);
      }, 1);
    }
  }
};
</script>
