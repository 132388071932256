<template>
  <va-form :item="item">
    <v-col sm="12">
      <v-row justify="end">
        <v-col cols="12" sm="12" lg="3">
          <AutoComplete
            label="UF"
            :model="filtro.uf"
            slug="uf"
            endpoint="combo/localidade/estado"
            slugId="id"
            slugDesc="description"
            @onChangeTextInput="onChangeTextInput"
          >
          </AutoComplete>
        </v-col>
      </v-row>
    </v-col>
  </va-form>
</template>

<script>
import AutoComplete from "@/components/detail/v_auto_complete.vue";

export default {
  components: {
    AutoComplete
  },
  data: function() {
    return {
      item: null,
      menu: false,
      filtro: {}
    };
  },
  methods: {
    onChangeTextInput(val) {
      this.filtro[val.slug] = val.value;
      this.filter();
    },
    filter() {
      this.$emit("onChangeFilter", this.filtro);
    }
  }
};
</script>
