<template>
  <va-edit-layout>
    <patient-syndrome-form
      :id="id"
      :title="title"
      :item="item"
    ></patient-syndrome-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item"]
};
</script>
