<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <base-material-card>
        <template v-slot:heading>
          <div class="display-2">
            {{ titleForm }}
          </div>
        </template>
        <SyndromeQuestionForm
          :id="id"
          :item="item"
          @saved="onSaved"
        ></SyndromeQuestionForm>
      </base-material-card>
    </va-aside-layout>
    <base-material-card title="">
      <template v-slot:heading>
        <v-card-actions>
          <div class="display-2">
            Perguntas da Condição Médica
          </div>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            text
            color="white"
            @click="onAction({ title: 'Nova Pergunta' })"
          >
            <v-icon>mdi-plus</v-icon>
            Novo
          </v-btn>
        </v-card-actions>
      </template>
      <syndrome-question-filter
        @onChangeFilter="onChangeFilter"
      ></syndrome-question-filter>
      <va-list
        :include="includes"
        disable-create-redirect
        ref="list"
        resource="syndrome_questions"
        disable-global-search
        disable-export
        disable-items-per-page
        disable-pagination
        disable-query-string
        disable-create
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          @item-action="onAction"
          disable-edit-redirect
          disable-clone
          disable-create-redirect
          disable-select
          disable-sort
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import SyndromeQuestionForm from "@/resources/syndrome/syndrome_questions/Form.vue";
import SyndromeQuestionFilter from "@/resources/syndrome/syndrome_questions/Filter.vue";
export default {
  props: ["resource", "title", "syndrome_id"],
  components: { SyndromeQuestionForm, SyndromeQuestionFilter },
  data() {
    return {
      includes: [
        { api: `default/SyndromeQuestion/search/params` },
        { syndrome_id: this.syndrome_id }
      ],
      fields: [
        { source: "id", sortable: true },
        { source: "desc_range", label: "Faixa", sortable: true },
        { source: "sequence", label: "Sequência", sortable: true },
        { source: "description", label: "Descrição", sortable: true },
        { source: "question_weight", label: "Peso Questão", sortable: true },
        { source: "desc_type_question", label: "Tipo Questão", sortable: true },
        { source: "desc_syndrome", label: "Condição Médica", sortable: true }
      ],
      asideTitle: null,
      titleForm: null,
      id: null,
      item: null,
      show: false
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onAction({ title, id, item }) {
      this.asideTitle = null;
      setTimeout(() => {
        this.titleForm =
          id == null ? "Nova Pergunta" : `Alteração Pergunta: ${id}`;
        this.asideTitle = " ";
      }, 10);
      this.id = id;
      if (item == null) {
        this.item = {};
        this.item.syndrome_id = this.syndrome_id;
      } else {
        this.item = item;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    onChangeFilter(val) {
      this.includes[1] = val;
      this.$refs.list.fetchData();
    }
  },
  mounted() {
    document.getElementsByClassName(
      "v-sheet theme--light v-toolbar v-toolbar--flat"
    )[1].style.height = "0px";
  }
};
</script>
