<template>
  <div>
    <router-view />

    <!-- <v-snackbar right rounded="pill" top v-model="alerta" :color="color">
      * Nova Mensagem Recebida *
    </v-snackbar> -->
  </div>
</template>

<script>
import gql from "graphql-tag";

// eslint-disable-next-line no-unused-vars
const CURRENT_ALERT = gql`
  query {
    alerts {
      robo_id
      description
    }
  }
`;
export default {
  name: "App",
  data: function() {
    return {
      currentAlert: null,
      alerta: null,
      color: "red"
    };
  },
};
</script>
