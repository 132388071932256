<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <base-material-card>
        <template v-slot:heading>
          <div class="display-2">
            {{ titleForm }}
          </div>
        </template>
        <company-form :id="id" :item="item" @saved="onSaved"></company-form>
      </base-material-card>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <company-filter
        @onChangeFilter="onChangeFilter"
        @onChangeNew="onChangeNew"
      ></company-filter>
      <va-list
        :include="includes"
        :filters="filters"
        disable-create-redirect
        ref="list"
        disable-global-search
        disable-export
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          @item-action="onAction"
          disable-edit-redirect
          disable-create-redirect
          disable-select
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      includes: [{ api: `default/Company/search/params` }, {}],
      filters: [
        { source: "id", label: "N° Registro" },
        { source: "name", label: "Nome" },
        { source: "email", label: "E-mail" },
        { source: "city", label: "Cidade", sortable: true },
        { source: "phone_cell", label: "Celular", sortable: true },
        { source: "phone", label: "Telefone", sortable: true }
      ],
      fields: [
        { source: "id", sortable: true },
        { source: "name", label: "Nome", sortable: true },
        { source: "email", label: "Email", sortable: true },
        { source: "phone_cell", label: "Celular", sortable: true },
        { source: "phone", label: "Telefone", sortable: true },
        { source: "city", label: "Cidade", sortable: true },
        { source: "address", label: "Endereço", sortable: true },
        { source: "number", label: "N°", sortable: true },
        { source: "uf", label: "UF", sortable: true }
      ],
      asideTitle: null,
      titleForm: null,
      id: null,
      item: null,
      show: false
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onAction({ title, id, item }) {
      this.asideTitle = null;
      setTimeout(() => {
        this.titleForm = title;
        this.asideTitle = " ";
      }, 10);
      this.id = id;
      if (item == null) {
        this.item = {};
      } else {
        this.item = item;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    onChangeFilter(val) {
      this.includes[1] = val;
      this.$refs.list.fetchData();
    }
  }
};
</script>
