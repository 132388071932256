/* eslint-disable no-undef */
import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";
import Vuetify from "vuetify/lib";
Vue.use(Vuetify);

import "vuetify-admin/src/loader";
import sanctumAuthProvider from "@/providers/auth/sanctum";
import jsonServerDataProvider from "@/providers/json";
import pt from "@/locales/pt-actions.json";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
import axios from "axios";

const vuetify = new Vuetify();
import VuetifyConfirm from "vuetify-confirm";

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: "Sim",
  buttonFalseText: "Não",
  buttonTrueColor: "blue",
  buttonFalseColor: "grey",
  buttonTrueFlat: false,
  buttonFalseFlat: true,
  color: "orange",
  title: "",
  property: "$confirm"
});
import service from "@/services/BaseService";

import VuetifyMask from "vuetify-mask";
Vue.use(VuetifyMask);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import moment from "moment";
moment.locale("pt-br");

import Vuesax from "vuesax";
Vue.use(Vuesax, {
  // options here
});
import "vuesax/dist/vuesax.css";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Axios instance
 */
const baseURL = service.baseURL;
//const baseURL = "https://robotc.admservice.com.br/api/v1/";

const http = axios.create({
  baseURL,
  // withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.token}`
  }
});

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "Odisseia",
  routes,
  locales: {
    pt
  },
  translations: ["pt"],
  dataProvider: jsonServerDataProvider(http),
  authProvider: sanctumAuthProvider(http, {
    routes: {
      login: "/singlelogin/admin",
      user: "/loginsss"
    }
  }),
  resources,
  http,
  options: {
    dateFormat: "long"
  }
});
