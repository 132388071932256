<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <base-material-card>
        <template v-slot:heading>
          <div class="display-2">
            {{ titleForm }}
          </div>
        </template>
        <syndrome-form :id="id" :item="item" @saved="onSaved"></syndrome-form>
      </base-material-card>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        disable-create-redirect
        ref="list"
        :filters="filters"
        disable-global-search
        disable-pagination
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          @item-action="onAction"
          disable-edit-redirect
          disable-create-redirect
          disable-select
          show-expand
        >
          <template v-slot:expanded-item="{ item }">
            <ListSyndromeQuestion :syndrome_id="item.id"></ListSyndromeQuestion>
            <ListSyndromeResult :syndrome_id="item.id"></ListSyndromeResult>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import ListSyndromeQuestion from "@/resources/syndrome/syndrome_questions/List.vue";
import ListSyndromeResult from "@/resources/syndrome/syndrome_result/List.vue";

export default {
  props: ["resource", "title"],
  components: {
    ListSyndromeQuestion,
    ListSyndromeResult
  },
  data() {
    return {
      filters: [
        { source: "id", label: "N° Registro" },
        { source: "title", label: "Titulo" },
        { source: "description", label: "Descrição" }
      ],
      fields: [
        { source: "id", sortable: true },
        { source: "title", label: "Titulo", sortable: true },
        { source: "description", label: "Descrição", sortable: true },
        { source: "desc_status", label: "Status", sortable: true }
      ],
      asideTitle: null,
      titleForm: null,
      id: null,
      item: null,
      show: false
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onAction({ title, id, item }) {
      this.asideTitle = null;
      setTimeout(() => {
        this.titleForm = title;
        this.asideTitle = " ";
      }, 10);
      this.id = id;
      if (item == null) {
        this.item = {};
        this.item.status = "A";
      } else {
        this.item = item;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    }
  }
};
</script>
