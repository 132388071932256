/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  {
    icon: "mdi-view-dashboard",
    text: i18n.t("menu.dashboard"),
    link: "/"
  },
  { divider: true },
  {
    text: "Cadastros",
    style: [{ textcolor: "red" }],
    children: [
      ...admin.getResourceLinks(["user"]),
      ...admin.getResourceLinks(["syndrome"]),
      ...admin.getResourceLinks(["person"]),
      ...admin.getResourceLinks(["range"]),
      // ...admin.getResourceLinks(["patient"]),
      // ...admin.getResourceLinks(["type_question"]),
      ...admin.getResourceLinks(["company"])
    ]
  }
];
