<template>
  <va-form
    :id="id"
    :item="item"
    v-model="model"
    disable-redirect
    @saved="$emit('saved')"
  >
    <v-card-text>
      <TextField
        slug="weight_answers"
        :model="model.weight_answers"
        label="Peso da Resposta"
        @onChangeTextInput="onChangeTextInput"
      ></TextField>
      <AutoComplete
        label="Condição Médica"
        :model="model.syndrome_id"
        slug="syndrome_id"
        endpoint="default/Syndrome"
        slugId="id"
        slugDesc="name"
        @onChangeTextInput="onChangeTextInput"
      >
      </AutoComplete>
      <AutoComplete
        label="Resultado Condição Médica"
        :model="model.syndrome_result_id"
        slug="syndrome_result_id"
        endpoint="default/SyndromeResult"
        slugId="id"
        slugDesc="name"
        @onChangeTextInput="onChangeTextInput"
      >
      </AutoComplete>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
import TextField from "@/components/detail/v_text_field.vue";
import AutoComplete from "@/components/detail/v_auto_complete.vue";

export default {
  props: ["id", "title", "item"],
  components: { TextField, AutoComplete },

  data() {
    return {
      model: this.item
    };
  },
  methods: {
    onChangeTextInput(val) {
      this.model[val.slug] = val.value;
    },
    onChangeData(val) {
      this.model[val.model] = val.value;
    }
  }
};
</script>
