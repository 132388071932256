/* eslint-disable no-undef */
import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  CHECK_ERROR,
  GET_NAME,
  GET_LOGIN,
  GET_PERMISSIONS
} from "./actions";
import Vue from "vue";

export default (axios, params = {}) => {
  params = {
    routes: {
      login: "/singlelogin",
      logout: "/logout",
      user: "/login"
    },
    getCredentials: ({ login, password, remember }) => {
      return {
        email_login: login,
        password: password,
        remember
      };
    },
    getName: u => u.name,
    getLogin: u => u.login,
    getPermissions: u => u.roles,
    ...params
  };

  let { routes, getCredentials, getName, getLogin, getPermissions } = params;

  return {
    [LOGIN]: async ({ login, password, remember }) => {
      try {
        let response = await axios.post(
          routes.login,
          getCredentials({ login, password, remember })
        );
        if (response.status != 200) {
          throw new Error(response.statusText);
        } else {
          localStorage.setItem("login", response.data.data.email_login);
          localStorage.setItem("token", response.data.token);
        }
        return Promise.resolve();
      } catch (error) {
        Vue.prototype.$vs.notify({
          text: error.response.data.message.toString(),
          color: "#ff5252",
          position: "center-top"
        });
      }
    },
    [LOGOUT]: async () => {
      // await axios.get(routes.login);
      localStorage.removeItem("login");
      localStorage.removeItem("nome_id");
      localStorage.removeItem("token");
      return Promise.resolve();
    },
    [CHECK_AUTH]: async () => {
      var token = localStorage.getItem("token");
      if (token != null) {
        var resp = {
          success: true,
          code: 200,
          message: "Usuário logado",
          data: []
        };
        return resp;
      } else {
        return null;
      }
    },
    [CHECK_ERROR]: ({ status }) => {
      if (status === 401 || status === 403) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    [GET_NAME]: user => getName(user),
    [GET_LOGIN]: user => getLogin(user),
    [GET_PERMISSIONS]: user => getPermissions(user)
  };
};
