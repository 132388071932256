<template>
  <v-autocomplete
    outlined
    dense
    :label="label"
    v-model="valueInput"
    :items="combo"
    clearable
  ></v-autocomplete>
</template>
<script>
import service from "@/components/detail/service_combo";

export default {
  props: ["label", "model", "slug", "endpoint", "slugId", "slugDesc"],
  data() {
    return {
      valueInput: this.model,
      combo: []
    };
  },
  created() {
    this.buscaDados();
  },
  methods: {
    buscaDados() {
      service
        .getDados({
          endpoint: this.endpoint,
          slugId: this.slugId,
          slugDesc: this.slugDesc
        })
        .then(value => {
          this.combo = value;
        });
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    valueInput(val) {
      this.$emit("onChangeTextInput", {
        slug: this.slug,
        value: val
      });
    }
  }
};
</script>
