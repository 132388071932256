<template>
  <va-form :item="item">
    <v-col sm="12">
      <!-- <v-col cols="12" sm="4" lg="3">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                dense
                v-model="dateRangeText"
                label="Período"
                persistent-hint
                format="DD/MM/YYYY"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" no-title scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(dates)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col> -->
      <!-- <v-col cols="12" sm="6" lg="3">
            <TextField
              slug="cidade"
              :model="filtro.cidade"
              label="Cidade"
              @onChangeTextInput="onChangeTextInput"
            ></TextField>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <TextField
              slug="estado"
              :model="filtro.estado"
              label="Estado"
              @onChangeTextInput="onChangeTextInput"
            ></TextField>
          </v-col> -->
      <v-row justify="end">
        <v-col cols="12" sm="12" lg="3">
          <AutoComplete
            label="UF"
            :model="filtro.uf"
            slug="uf"
            endpoint="combo/localidade/estado"
            slugId="id"
            slugDesc="description"
            @onChangeTextInput="onChangeTextInput"
          >
          </AutoComplete>
        </v-col>
        <v-col cols="12" sm="12" lg="3">
          <AutoComplete
            label="Status"
            :model="filtro.status"
            slug="status"
            endpoint="combo/user/status"
            slugId="id"
            slugDesc="description"
            @onChangeTextInput="onChangeTextInput"
          >
          </AutoComplete>
        </v-col>
      </v-row>
    </v-col>
  </va-form>
</template>

<script>
import moment from "moment";
//import TextField from "@/components/detail/v_text_field.vue";
import AutoComplete from "@/components/detail/v_auto_complete.vue";

export default {
  components: {
    AutoComplete
    // TextField,
  },
  data: function() {
    return {
      item: null,
      menu: false,
      filtro: { status: "B" },
      dates: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ]
    };
  },
  methods: {
    onChangeTextInput(val) {
      this.filtro[val.slug] = val.value;
      this.filter();
    },
    newUser() {
      this.$emit("onChangeNewUser");
    },
    filter() {
      //this.filtro.periodo = this.dates;
      this.$emit("onChangeFilterUser", this.filtro);
    }
  },
  computed: {
    dateRangeText() {
      if (this.dates[0] && this.dates[1] != null) {
        var dt_ini = moment(this.dates[0]).format("DD/MM/YYYY");
        var dt_fim = moment(this.dates[1]).format("DD/MM/YYYY");
        const diff_data = moment(dt_ini, "YYYYMMDD").diff(
          moment(dt_fim, "YYYYMMDD"),
          "days"
        );
        if (diff_data >= 0) {
          return `${dt_fim} - ${dt_ini}`;
        } else {
          return `${dt_ini} - ${dt_fim}`;
        }
      } else {
        return `${moment(this.dates[0]).format("DD/MM/YYYY")} - ${moment(
          this.dates[0]
        ).format("DD/MM/YYYY")}`;
      }
    }
  }
};
</script>
