<template>
  <va-form :item="item">
    <v-col sm="12">
      <v-row justify="end">
        <v-col cols="12" sm="12" lg="12">
          <v-row>
            <v-col cols="12" sm="12" lg="3">
              <TextField
                slug="name"
                :model="filtro.name"
                label="Nome"
                @onChangeTextInput="onChangeTextInput"
              ></TextField>
            </v-col>
            <v-col cols="12" sm="12" lg="3">
              <TextField
                slug="cep"
                :model="filtro.cep"
                label="Cep"
                mask="#####-###"
                @onChangeTextInput="onChangeTextInput"
              ></TextField>
            </v-col>
            <v-col cols="12" sm="12" lg="3">
              <TextField
                slug="city"
                :model="filtro.city"
                label="Cidade"
                @onChangeTextInput="onChangeTextInput"
              ></TextField>
            </v-col>
            <v-col cols="12" sm="12" lg="3">
              <TextField
                slug="uf"
                :model="filtro.uf"
                label="Estado"
                @onChangeTextInput="onChangeTextInput"
              ></TextField>
            </v-col>
          </v-row>
          <v-row align="end">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="filter()">
              <v-icon>mdi-filter</v-icon>
              Filtrar
            </v-btn>
            <v-btn text color="primary" @click="newUser()">
              <v-icon>mdi-plus</v-icon>
              Novo
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </va-form>
</template>

<script>
import moment from "moment";
import TextField from "@/components/detail/v_text_field.vue";

export default {
  components: {
    TextField
  },
  data: function() {
    return {
      item: null,
      menu: false,
      filtro: {},
      dates: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ],
      comboStatus: [
        { id: "A", description: "Autorizado" },
        { id: "B", description: "Não Autorizado" },
        { id: "D", description: "Admin Web" },
        { id: "", description: "Todos" }
      ]
    };
  },
  methods: {
    onChangeTextInput(val) {
      this.filtro[val.slug] = val.value;
    },
    newUser() {
      this.$emit("onChangeNewPatient");
    },
    filter() {
      //this.filtro.periodo = this.dates;
      this.$emit("onChangeFilterPatient", this.filtro);
    }
  },
  computed: {
    dateRangeText() {
      if (this.dates[0] && this.dates[1] != null) {
        var dt_ini = moment(this.dates[0]).format("DD/MM/YYYY");
        var dt_fim = moment(this.dates[1]).format("DD/MM/YYYY");
        const diff_data = moment(dt_ini, "YYYYMMDD").diff(
          moment(dt_fim, "YYYYMMDD"),
          "days"
        );
        if (diff_data >= 0) {
          return `${dt_fim} - ${dt_ini}`;
        } else {
          return `${dt_ini} - ${dt_fim}`;
        }
      } else {
        return `${moment(this.dates[0]).format("DD/MM/YYYY")} - ${moment(
          this.dates[0]
        ).format("DD/MM/YYYY")}`;
      }
    }
  }
};
</script>
<style>
.divider_filter {
  width: 10px;
}
</style>
