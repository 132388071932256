<template>
  <div>
    <v-row class="row_perguntas">
      <v-col
        cols="12"
        sm="12"
        lg="6"
        v-for="question in questions"
        :key="question.id"
      >
        <v-card :color="question.color">
          <v-card-title primary-title>
            Pergunta: {{ question.pergunta }}
          </v-card-title>
          <v-col class="text-start" cols="12">
            Resposta Correta: {{ question.resposta_correta }}
          </v-col>
          <v-col class="text-start" cols="12">
            Resposta Usuário: {{ question.resposta_usuario }}
          </v-col>
          <v-col class="text-end" cols="12"> Nota: {{ question.nota }} </v-col>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data() {
    return {
      questions: []
    };
  },
  created() {
    this.setQuestions();
  },
  methods: {
    setQuestions() {
      this.questions = [];
      this.item.answer_json.questions.forEach(element => {
        var question = {};
        question.pergunta = element.syndrome_description;
        question.resposta_correta = element.correct_answer;
        question.resposta_usuario = element.patient_answer;
        question.nota = element.note;
        if (question.resposta_correta == question.resposta_usuario) {
          question.color = "#50e6033b";
        } else {
          question.color = "#e613031f";
        }
        this.questions.push(question);
      });
    }
  }
};
</script>
<style>
.row_perguntas{
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
