<template>
  <va-form
    :id="id"
    :item="item"
    v-model="model"
    disable-redirect
    @saved="$emit('saved')"
  >
    <v-card-text>
      <TextField
        slug="name"
        :model="model.name"
        label="Nome"
        @onChangeTextInput="onChangeTextInput"
      ></TextField>
      <TextField
        slug="email"
        :model="model.email"
        label="Email"
        @onChangeTextInput="onChangeTextInput"
      ></TextField>
      <TextField
        slug="phone_cell"
        :model="model.phone_cell"
        label="Celular"
        mask="(##)#####-####"
        @onChangeTextInput="onChangeTextInput"
      ></TextField>
      <TextField
        slug="phone"
        :model="model.phone"
        label="Telefone"
        mask="(##)#####-####"
        @onChangeTextInput="onChangeTextInput"
      ></TextField>
      <TextField
        slug="cep"
        :model="model.cep"
        label="Cep"
        mask="#####-###"
        @onChangeTextInput="onChangeTextInput"
      ></TextField>
      <div v-if="updateCep == null">
        <TextField
          slug="address"
          :model="model.address"
          label="Endereço"
          @onChangeTextInput="onChangeTextInput"
        ></TextField>
        <TextField
          slug="city"
          :model="model.city"
          label="Cidade"
          @onChangeTextInput="onChangeTextInput"
        ></TextField>
        <TextField
          slug="uf"
          :model="model.uf"
          label="UF"
          @onChangeTextInput="onChangeTextInput"
        ></TextField>
      </div>
      <TextField
        slug="number"
        :model="model.number"
        label="Numero"
        mask="#####"
        @onChangeTextInput="onChangeTextInput"
      ></TextField>
      <TextField
        slug="complement"
        :model="model.complement"
        label="Complemento"
        @onChangeTextInput="onChangeTextInput"
      ></TextField>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
import TextField from "@/components/detail/v_text_field.vue";
import service from "@/services/BaseService";

export default {
  props: ["id", "title", "item"],
  components: { TextField },

  data() {
    return {
      model: this.item,
      updateCep: null
    };
  },
  methods: {
    onChangeTextInput(val) {
      if (val.slug == "cep") {
        if (val.value.length == 9) {
          this.buscaCep(val.value);
        }
      }
      this.model[val.slug] = val.value;
    },
    async buscaCep(cep) {
      this.updateCep = "";
      cep = cep.replaceAll("-", "");

      var getvalues = new service(`cep/${cep}`);
      await getvalues
        .find()
        .then(value => {
          if (value.data.code == 200) {
            this.updateCep = null;
            this.model.address = value.data.data.logradouro;
            this.model.city = value.data.data.localidade;
            this.model.uf = value.data.data.uf;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(e => {
          this.updateCep = null;
          this.model.address = "";
          this.model.city = "";
          this.model.uf = "";
        });
    }
  }
};
</script>
