<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <base-material-card>
        <template v-slot:heading>
          <div class="display-2">
            {{ titleForm }}
          </div>
        </template>
        <patient-syndrome-form
          :id="id"
          :item="item"
          @saved="onSaved"
        ></patient-syndrome-form>
      </base-material-card>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        disable-create-redirect
        ref="list"
        :filters="filters"
        disable-global-search
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          @item-action="onAction"
          disable-edit-redirect
          disable-create-redirect
          disable-select
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: ["id"],
      fields: [{ source: "id", sortable: true }],
      asideTitle: null,
      titleForm: null,
      id: null,
      item: null,
      show: false
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onAction({ title, id, item }) {
      this.asideTitle = null;
      setTimeout(() => {
        this.titleForm = title;
        this.asideTitle = " ";
      }, 10);
      this.id = id;
      if (item == null) {
        this.item = {};
      } else {
        this.item = item;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    }
  }
};
</script>
