import {
  GET_LIST,
  GET_MANY,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from "./actions";

import FetchJson from "./utils/fetchJson";
import qs from "qs";
import router from "@/router";
import events from "@/main";

export default httpClient => {
  if (typeof httpClient === "string") {
    httpClient = new FetchJson(httpClient);
  }

  const errors = {
    403: "Não autorizado"
  };

  const withInclude = params => {
    let query = {};

    if (params.include) {
      let { embed, expand } = params.include;
      query = {
        _embed: embed,
        _expand: expand
      };
    }

    return query;
  };

  return {
    [GET_LIST]: async (resource, params) => {
      const { pagination, sort, filter, include } = params;
      let query = {
        ...withInclude(params),
        ...filter
      };

      if (pagination) {
        let { page, perPage } = pagination;

        query = {
          ...query,
          _start: (page - 1) * perPage,
          _end: page * perPage
        };
      }

      if (sort && sort.length) {
        query = {
          ...query,
          _sort: sort.map(item => item.by),
          _order: sort.map(item => (item.desc ? "desc" : "asc"))
        };
      }
      if (include && include.length) {
        //CASO A CONSULTA POSSUA FILTRO ESPECIFICO NA TELA
        //PODE SER ENVIADO ESSE FILTRO EM INCLUDE[1]
        if (include.length == 2) {
          let _query = {
            ...query,
            ...include[1]
          };
          return await httpClient
            .get(
              `${include[0].api}?${qs.stringify(_query, {
                arrayFormat: "repeat"
              })}`
            )
            .then(response => {
              return {
                data: response.data.data.data,
                total: response.data.data["x-total-count"]
              };
            })
            .catch(error => {
              router.push({
                name: "Error",
                params: {
                  error: {
                    status: error.response.status,
                    message: errors[error.response.status]
                  }
                }
              });
              return [];
            });
        } else {
          return await httpClient
            .get(
              `${include[0].api}/params?${qs.stringify(query, {
                arrayFormat: "repeat"
              })}`
            )
            .then(response => {
              return {
                data: response.data.data.data,
                total: response.data.data["x-total-count"]
              };
            })
            .catch(error => {
              router.push({
                name: "Error",
                params: {
                  error: {
                    status: error.response.status,
                    message: errors[error.response.status]
                  }
                }
              });
              return [];
            });
        }
      } else {
        return await httpClient
          .get(
            `${resource}/search/params?${qs.stringify(query, {
              arrayFormat: "repeat"
            })}`
          )
          .then(response => {
            return {
              data: response.data.data.data,
              total: response.data.data["x-total-count"]
            };
          })
          .catch(error => {
            router.push({
              name: "Error",
              params: {
                error: {
                  status: error.response.status,
                  message: errors[error.response.status]
                }
              }
            });
            return [];
          });
      }
    },
    [GET_MANY]: (resource, params) => {
      httpClient.get(
        `${resource}?${qs.stringify(
          {
            ...withInclude(params),
            id: params.ids
          },
          { arrayFormat: "repeat" }
        )}`
      );
    },
    [GET_ONE]: async (resource, params) => {
      return await httpClient
        .get(`${resource}/${params.id}?${qs.stringify(withInclude(params))}`)
        .then(response => {
          return response.data;
        });
    },
    [CREATE]: async (resource, params) => {
      try {
        return await httpClient.post(resource, params.data);
      } catch ({ response }) {
        let { data, status, statusText } = response;

        return Promise.reject({
          message: statusText,
          status,
          ...(data || {})
        });
      }
    },
    [UPDATE]: async (resource, params) => {
      try {
        return await httpClient.put(`${resource}/${params.id}`, params.data);
      } catch ({ response }) {
        let { data, status, statusText } = response;

        return Promise.reject({
          message: statusText,
          status,
          ...(data || {})
        });
      }
    },
    [UPDATE_MANY]: (resource, params) =>
      Promise.all(
        params.ids.map(id => httpClient.put(`${resource}/${id}`, params.data))
      ).then(() => Promise.resolve()),
    [DELETE]: async (resource, params) => {
      await httpClient.delete(`${resource}/${params.id}`);
      switch (resource) {
        case "default/PointInactive":
          events.refreshPointInactiveMap();
          break;
        case "default/Point":
          events.refreshPointMap();
          break;
        default:
          break;
      }
    },
    [DELETE_MANY]: async (resource, params) => {
      await Promise.all(
        params.ids.map(id => httpClient.delete(`${resource}/${id}`))
      ).then(() => Promise.resolve());

      switch (resource) {
        case "default/PointInactive":
          events.refreshPointInactiveMap();
          break;
        default:
          break;
      }
    }
  };
};
