<template>
  <va-create-layout>
    <syndrome-result-form
      :title="title"
      :item="item"
    ></syndrome-result-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"]
};
</script>
