import service from "@/services/BaseService";

export default {
  // Busca dados na api e retorna lista para a combo
  async getDados({ endpoint, slugId, slugDesc }) {
    let items = [];
    try {
      var getCombo = new service(endpoint);
      await getCombo.find().then(value => {
        for (let i = 0; i < value.data.data.length; i++) {
          items.push({
            value: value.data.data[i][slugId],
            text: value.data.data[i][slugDesc]
          });
        }
      });
      return items;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  async getCentroCusto({ endpoint, slugId, slugDesc }) {
    let items = [];
    try {
      var getCombo = new service(endpoint);
      await getCombo.find().then(value => {
        for (let i = 0; i < value.data.data.length; i++) {
          items.push({
            value: value.data.data[i][slugId],
            text: value.data.data[i][slugDesc],
            centrocusto: value.data.data[i].centrocusto,
            nivel: value.data.data[i].nivel,
            ativo: value.data.data[i].ativo
          });
        }
      });
      return items;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  async getGenero({ endpoint, slugId, slugDesc }) {
    let items = [];
    try {
      var getCombo = new service(endpoint);
      await getCombo.find().then(value => {
        for (let i = 0; i < value.data.data.length; i++) {
          items.push({
            value: value.data.data[i][slugId],
            text: value.data.data[i][slugDesc],
            tipolancamento: value.data.data[i].tipolancamento
          });
        }
      });
      return items;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  async getDrawerRobot({ endpoint }) {
    let items = [];
    try {
      var getCombo = new service(endpoint);
      await getCombo.find().then(value => {
        if (value.data.data.length > 0) {
          var qtdDrawer = value.data.data[0]["qtde_gavetas"];
          qtdDrawer = qtdDrawer + 1;
          for (let i = 1; i < qtdDrawer; i++) {
            items.push({
              value: i,
              text: i.toString()
            });
          }
        }
      });
      return items;
    } catch (error) {
      return [];
    }
  },
  // Busca dados na api e retorna lista para a combo
  async getPointsMap({ endpoint, slugId, slugDesc }) {
    let items = [];
    try {
      var getCombo = new service(endpoint);
      await getCombo.find().then(value => {
        for (let i = 0; i < value.data.data.data.length; i++) {
          items.push({
            value: value.data.data.data[i][slugId],
            text: value.data.data.data[i][slugDesc]
          });
        }
      });
      return items;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  getImagem({ syndrome_question_id }) {
    let item = [];
    var getValues = new service(
      `SyndromeQuestion/image/${syndrome_question_id}`
    );
    return getValues.find().then(value => {
      value.data.data.forEach(element => {
        element.url = `${service.baseURL}SyndromeQuestion/image/${element.id}`;
        item.push(element);
      });
      return item;
    });
  },
  postImagem({ item }) {
    var postValue = new service(`SyndromeQuestion/image`);
    return postValue
      .createFormData(item)
      .then(value => {
        return value;
      })
      .catch(e => {
        return e;
      });
  },
  deleteImagem({ id }) {
    var postValue = new service(`SyndromeQuestion/image`);
    return postValue
      .delete(id)
      .then(value => {
        return value;
      })
      .catch(e => {
        return e;
      });
  },
  post({ url, item }) {
    var postValue = new service(url);
    return postValue
      .create(item)
      .then(value => {
        return value;
      })
      .catch(e => {
        return e;
      });
  },
  async getRateio({ endpoint }) {
    let items = [];
    try {
      var getCombo = new service(endpoint);
      await getCombo.find().then(value => {
        for (let i = 0; i < value.data.data.length; i++) {
          items.push(value.data.data[i]);
        }
      });
      return items;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
};
