<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <base-material-card>
        <template v-slot:heading>
          <div class="display-2">
            {{ titleForm }}
          </div>
        </template>
        <SyndromeResultForm
          :id="id"
          :item="item"
          @saved="onSaved"
        ></SyndromeResultForm>
      </base-material-card>
    </va-aside-layout>
    <base-material-card title="">
      <template v-slot:heading>
        <v-card-actions>
          <div class="display-2">
            Resultados da Condição Médica
          </div>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            text
            color="white"
            @click="onAction({ title: 'Nova Pergunta' })"
          >
            <v-icon>mdi-plus</v-icon>
            Novo
          </v-btn>
        </v-card-actions>
      </template>
      <va-list
        :include="includes"
        disable-create-redirect
        ref="list"
        resource="syndrome_result"
        disable-global-search
        disable-export
        disable-items-per-page
        disable-pagination
        disable-query-string
        disable-create
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          @item-action="onAction"
          disable-edit-redirect
          disable-clone
          disable-create-redirect
          disable-select
          disable-sort
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import SyndromeResultForm from "@/resources/syndrome/syndrome_result/Form.vue";
export default {
  props: ["resource", "title", "syndrome_id"],
  components: { SyndromeResultForm },
  data() {
    return {
      includes: [
        { api: `default/SyndromeResult/search/params` },
        { syndrome_id: this.syndrome_id }
      ],
      fields: [
        { source: "id", sortable: true },
        { source: "description", label: "Descrição", sortable: true },
        { source: "weight_start", label: "Peso Inicio", sortable: true },
        { source: "weight_end", label: "Peso Fim", sortable: true },
        { source: "desc_syndrome", label: "Condição Médica", sortable: true },
        { source: "desc_type", label: "Tipo", sortable: true }
      ],
      asideTitle: null,
      titleForm: null,
      id: null,
      item: null,
      show: false
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onAction({ title, id, item }) {
      this.asideTitle = null;
      setTimeout(() => {
        this.titleForm =
          id == null ? "Novo Resultado" : `Alterar Resultado: ${id}`;
        this.asideTitle = " ";
      }, 10);
      this.id = id;
      if (item == null) {
        this.item = {};
        this.item.syndrome_id = this.syndrome_id;
      } else {
        this.item = item;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    }
  },
  mounted() {
    document.getElementsByClassName(
      "v-sheet theme--light v-toolbar v-toolbar--flat"
    )[1].style.height = "0px";
  }
};
</script>
