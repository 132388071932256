<template>
  <va-create-layout>
    <syndrome-questions-form
      :title="title"
      :item="item"
    ></syndrome-questions-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"]
};
</script>
