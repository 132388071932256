<template>
  <!-- Input sem mascara -->
  <div v-if="mask == null">
    <v-text-field
      :rules="rules"
      outlined
      dense
      :label="label"
      v-model="valueInput"
      :disabled="disabled"
    ></v-text-field>
  </div>
  <!-- Input com mascara -->
  <div v-else>
    <v-text-field
      :rules="rules"
      outlined
      dense
      :label="label"
      :disabled="disabled"
      v-mask="mask"
      v-model="valueInput"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  props: ["label", "model", "mask", "disabled", "slug", "required"],
  data() {
    return {
      rules: this.required == true ? [v => !!v || "Required"] : [],
      valueInput: this.model
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    valueInput(val) {
      this.$emit("onChangeTextInput", {
        slug: this.slug,
        value: val
      });
    }
  }
};
</script>
